const Color = {
    ThemeColor : "#147D9F",
    DeleteUser: "#C41E3A"
}

const getDbDataWithId = querySnapshot => {
    let data = [];
    querySnapshot.forEach((doc) => {
        let docData = doc.data();
        data.push(docData.id);
    });
    return data;
}

export {Color, getDbDataWithId}