import React, { useState } from 'react';
import { Color } from "./../../src/global/util";
import { BarsOutlined } from "@ant-design/icons";

export default (props) => {
    let {s, a, c, b, t, u, bc, lh, to, ri, mt, mr, ml, mb, w, h, boc, center, style, ...rest} = props

    let st = { 
        ...{
          color : c ? c : Color.themeFontColor,
          fontSize : s,
          lineHeight : lh,
          fontWeight: b ? 'bold' : undefined,
          textAlign : center ? 'center' : undefined,
          marginTop : mt ? mt : undefined,
          marginRight : mr ? mr : undefined,
          marginBottom : mb ? mb : undefined,
          marginLeft : ml ? ml : undefined,
          width : w ? w : "100%",
          height : h ? h : 55,
          top: to ? to : undefined,
          right: ri ? ri : undefined,
          borderColor : boc ? boc : Color.themeFontColor,
          backgroundColor : bc ? bc : undefined,
          position: a ? "absolute" : undefined,
          overflow: "hidden"
        }, ...style};

  return (
    <div className="px-2 py-2 h-14 bg-grey flex justify-between" 
        {...rest} style={st}>
        <div className="text-xl flex justify-center" style={{ alignItems: "center" }}>
            <BarsOutlined onClick={() => {
              let elem = document.getElementById("sidebar");
              if(!elem) return;
              if(elem.style.display == "none"){
                elem.style.display = "flex";
              } else {
                elem.style.display = "none";
              }
            }} style={{ fontSize:30, marginRight: 8 }} size={30}/>{t}
        </div>
        {props.children}
    </div>
  );
}