import React, { lazy, Suspense, useEffect, useState } from "react";
import { createCitizensFromUsersData, getCitizensFromUsersDataCount, updateAllUsersDocuments, tempMethod } from "../../API/api";
import * as turf from '@turf/turf';

const HomeMap = lazy(() => import("./HomeMap"));
const Vehicle = lazy(() => import("./vehicle"));
const Complaint = lazy(() => import("./complaints"));
const Task = lazy(() => import("./tasks"));
const Establishment = lazy(() => import("./household"));
const Checkpoint = lazy(() => import("./checkpoints"));
const Staff = lazy(() => import("./staff"));
const Ctpt = lazy(() => import("./ctpt"));
const Feedback = lazy(() => import("./feedback"));

export default function Home(props) {

    useEffect(() => {
        // tempMethod();
        // getCitizensFromUsersDataCount();
        // createCitizensFromUsersData();
        // getCitizensFromUsersDataCount();
        // if(window.localStorage.mun == "chatrapur"){
        //     updateAllUsersDocuments();
        // }
        
    }, [])

    // const wardBoundaryData = JSON.parse(window?.localStorage?.wardBoundaryData)
    // const initialWards = wardBoundaryData.map(ward =>
    //     ward.Coordinates.map(coord => [coord.lat, coord.lng])
    // );
    // const [wardBoundaries, setWardBoundaries] = useState(initialWards);
    // const [municipalityBoundary, setMunicipalityBoundary] = useState([]);
    // const [boundaryString, setBoundaryString] = useState('');

    // Convert ward data to a format suitable for turf.js
    

    // useEffect(() => {
    //     // Convert all ward boundaries to Turf polygons
    //     const allPolygons = wardBoundaryData.map(ward => {
    //         return turf.polygon([ward.Coordinates.map(coord => [coord.lng, coord.lat])]);
    //     });

    //     // Combine all polygons into a single polygon
    //     const combinedPolygon = allPolygons.reduce((acc, polygon) => {
    //         if (!acc) return polygon;
    //         return turf.union(acc, polygon);
    //     }, null);

    //     if (combinedPolygon) {
    //         // Extract the outer boundary coordinates
    //         const outerBoundary = combinedPolygon.geometry.coordinates[0] || [];
    //         const combinedCoords = outerBoundary.map(coord => ({
    //             lat: coord[1],
    //             lng: coord[0]
    //         }));
    //         setMunicipalityBoundary(combinedCoords);

    //         // Convert coordinates to the desired string format
    //         const formattedString = combinedCoords.map(coord => `${coord.lng},${coord.lat},0`).join(' ');
    //         setBoundaryString(formattedString);
    //     }
    // }, [wardBoundaryData]);

    // console.log("boundaryString: ", boundaryString)
    const containerStyle = {
        backgroundColor: "#eee",
        width: "100%",
        height: "105vh",
        display: "flex",
        flexWrap: "wrap", // Allow wrapping
    };

    const leftColumnStyle = {
        width: "60%",
        height: "100%",
        padding: "1% 2%",
        display: "flex",
        flexDirection: "column",
    };

    const rightColumnStyle = {
        width: "40%",
        height: "100%",
        paddingRight: "2%",
        paddingTop: "1%",
        display: "flex",
        flexDirection: "column",
    };

    const itemStyle = {
        width: "100%",
        paddingBottom: "2%",
        paddingTop: "2%",
    };

    const flexItemStyle = {
        display: "flex",
        width: "100%",
        height: "20%",
    };

    const innerFlexItemStyle = {
        width: "100%",
        height: "100%",
        paddingRight: "1%",
    };

    return (
        <div style={containerStyle}>
            <div style={leftColumnStyle}>
                <div style={{ ...itemStyle, height: "64%" }}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <HomeMap parentData={props} />
                    </Suspense>
                </div>
                <div style={{ ...itemStyle, height: "18%" }}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Establishment parentData={props} />
                    </Suspense>
                </div>
                <div style={flexItemStyle}>
                    <div style={innerFlexItemStyle}>
                        <Suspense fallback={<div>Loading...</div>}>
                            <Ctpt parentData={props} />
                        </Suspense>
                    </div>
                    <div style={innerFlexItemStyle}>
                        <Suspense fallback={<div>Loading...</div>}>
                            <Feedback parentData={props} />
                        </Suspense>
                    </div>
                    <div style={{ width: "100%", height: "100%" }}>
                        <Suspense fallback={<div>Loading...</div>}>
                            <Checkpoint parentData={props} />
                        </Suspense>
                    </div>
                </div>
            </div>
            <div style={rightColumnStyle}>
                <div style={{ ...itemStyle, height: "25%", backgroundColor: "white", borderRadius: 10 }}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Vehicle parentData={props} />
                    </Suspense>
                </div>
                <div style={{ ...itemStyle, height: "25%" }}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Complaint parentData={props} />
                    </Suspense>
                </div>
                <div style={{ ...itemStyle, height: "25%" }}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Task parentData={props} />
                    </Suspense>
                </div>
                <div style={{ ...itemStyle, height: "25%" }}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Staff parentData={props} />
                    </Suspense>
                </div>
            </div>
            <style>
                {`
                    @media (max-width: 1024px) {
                        div[style*="flex-direction: column"] {
                            width: 100% !important;
                        }
                    }
                `}
            </style>
        </div>
    );
};
