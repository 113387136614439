export const Color = {
    gradientColor1 : "#00cc66",
    gradientColor2 : "#99ff33",
    themeColor : "#fff",
    themeFontColor : "#4C9A2A",
    white : "#fff",
    gray : "gray",
    black : "#000",
    green : "#3cb878",
    backgroundThemeColor : "#eee",
    backgroundModalColor : "rgba(52, 52, 52, 0.6)",
    lightGrayColor : "#bbb",
    red: "#FF6347",
    yellow : "yellow",
    blue : "blue",
}

export const getCurrentDateFmt = () => getCurrentDateFmtFromDate(new Date());

export const getCurrentDateFmtFromDate = (date) => {
    date = date || new Date();
    let dd;
    try {
        dd = date.getDate();
    }catch(e){
        date = new Date();
        dd = date.getDate();
    }
    let mm = date.getMonth() + 1;
    let yy = date.getFullYear();
    if(mm < 10) mm = "0" + mm;
    if(dd < 10) dd = "0" + dd;
    return yy + "-" + mm + "-" + dd;
}

export const HOST = "https://us-central1-binimise-v1.cloudfunctions.net/"

export const HOUSEHOLD_REPORT_OF_WARDS  = "householdReportOfWards";
export const COMPLAINT_REPORT_OF_WARDS  = "WardWiseComplaintReport";
export const CTPT_REPORT_OF_WARDS  =  "WardWiseCtptReport";

export const TIMEFRAME =[
    {key:"1 hr",value:"1 HRS"},
    {key:"2 hrs",value:"2 HRS"},
    {key:"4 hrs",value:"4 HRS"},
    {key:"6 hrs",value:"6 HRS"},
    {key:"8 hrs",value:"8 HRS"},
    {key:"10 hrs",value:"10 HRS"},
    {key:"24 hrs",value:"24 HRS"},
    {key:"2 days",value:"2 DAYS"},
    {key:"3 days",value:"3 DAYS"},
    {key:"4 days",value:"4 DAYS"},
    {key:"5 days",value:"5 DAYS"},
    {key:"6 days",value:"6 DAYS"},
    {key:"1 Week",value:"1 WEEK"},
    {key:"2 Weeks",value:"2 WEEKS"},
    {key:"3 Weeks",value:"3 WEEKS"},
    {key:"4 Weeks",value:"4 WEEKS"},
    {key:"2 Months",value:"2 MONTHS"},
]

export const STATUS_ARRAY = [
    { name: "ASSIGNED",value: "ASSIGNED" },
    { name: "PENDING",value: "PENDING"},
    { name: "CLOSED",value: "CLOSED" },
];

export const DOWNLOAD_ARRAY = [
    { name: "All", value: "ALL" },
    { name: "ACTIVE",value: "ACTIVE" },
    { name: "ASSIGNED",value: "ASSIGNED" },
    { name: "PENDING",value: "PENDING"},
    { name: "CLOSED", value: "CLOSED" }
];

export const COLOR = [
    "red", "green", "yellow", "pink", "black","maroon","purple","fuchsia","lime","olive","navy","teal","aqua","coral","darkmagenta","darkorchid","darkslategray","darkviolet","goldenrod",
"red", "green", "yellow", "pink", "black","maroon","purple","fuchsia","lime","olive","navy","teal","aqua","coral","darkmagenta","darkorchid","darkslategray","darkviolet","goldenrod",
"red", "green", "yellow", "pink", "black","maroon","purple","fuchsia","lime","olive","navy","teal","aqua","coral","darkmagenta","darkorchid","darkslategray","darkviolet","goldenrod",
"red", "green", "yellow", "pink", "black","maroon","purple","fuchsia","lime","olive","navy","teal","aqua","coral","darkmagenta","darkorchid","darkslategray","darkviolet","goldenrod",
"red", "green", "yellow", "pink", "black","maroon","purple","fuchsia","lime","olive","navy","teal","aqua","coral","darkmagenta","darkorchid","darkslategray","darkviolet","goldenrod",
"red", "green", "yellow", "pink", "black","maroon","purple","fuchsia","lime","olive","navy","teal","aqua","coral","darkmagenta","darkorchid","darkslategray","darkviolet","goldenrod",
"red", "green", "yellow", "pink", "black","maroon","purple","fuchsia","lime","olive","navy","teal","aqua","coral","darkmagenta","darkorchid","darkslategray","darkviolet","goldenrod",
"red", "green", "yellow", "pink", "black","maroon","purple","fuchsia","lime","olive","navy","teal","aqua","coral","darkmagenta","darkorchid","darkslategray","darkviolet","goldenrod",
"red", "green", "yellow", "pink", "black","maroon","purple","fuchsia","lime","olive","navy","teal","aqua","coral","darkmagenta","darkorchid","darkslategray","darkviolet","goldenrod",
"red", "green", "yellow", "pink", "black","maroon","purple","fuchsia","lime","olive","navy","teal","aqua","coral","darkmagenta","darkorchid","darkslategray","darkviolet","goldenrod",
"red", "green", "yellow", "pink", "black","maroon","purple","fuchsia","lime","olive","navy","teal","aqua","coral","darkmagenta","darkorchid","darkslategray","darkviolet","goldenrod",
"red", "green", "yellow", "pink", "black","maroon","purple","fuchsia","lime","olive","navy","teal","aqua","coral","darkmagenta","darkorchid","darkslategray","darkviolet","goldenrod",
"red", "green", "yellow", "pink", "black","maroon","purple","fuchsia","lime","olive","navy","teal","aqua","coral","darkmagenta","darkorchid","darkslategray","darkviolet","goldenrod",
"red", "green", "yellow", "pink", "black","maroon","purple","fuchsia","lime","olive","navy","teal","aqua","coral","darkmagenta","darkorchid","darkslategray","darkviolet","goldenrod",
"red", "green", "yellow", "pink", "black","maroon","purple","fuchsia","lime","olive","navy","teal","aqua","coral","darkmagenta","darkorchid","darkslategray","darkviolet","goldenrod",
"red", "green", "yellow", "pink", "black","maroon","purple","fuchsia","lime","olive","navy","teal","aqua","coral","darkmagenta","darkorchid","darkslategray","darkviolet","goldenrod",
"red", "green", "yellow", "pink", "black","maroon","purple","fuchsia","lime","olive","navy","teal","aqua","coral","darkmagenta","darkorchid","darkslategray","darkviolet","goldenrod",
"red", "green", "yellow", "pink", "black","maroon","purple","fuchsia","lime","olive","navy","teal","aqua","coral","darkmagenta","darkorchid","darkslategray","darkviolet","goldenrod"]

export const GETCOLORBYINDEX = [
    "yellow","green","red","violet","blue","#b87333","silver","gold","#00A651",
    "#538EC9","#E0726C","#e5e4e2","yellow","green","red","violet","blue","#b87333",
    "silver","gold","#00A651","#538EC9","#E0726C","#e5e4e2","yellow","green","red","violet",
    "blue","#b87333","silver","gold","#00A651","#538EC9","#E0726C","#e5e4e2","yellow","green",
    "red","violet","blue","#b87333","silver","gold","#00A651","#538EC9","#E0726C","#e5e4e2"
]

export const Places_Names  = [
    {id:"featuredPlaces",name:"Featured Places"},
    {id:"touristDestination",name:"Tourist Destination"},
    {id:"foodDrink",name:"Food & Drink"},
    {id:"hotels",name:"Hotels"},
    {id:"entertainment",name:"Entertainment"},
    {id:"sport",name:"Sport"},
    {id:"shopping",name:"Shopping"},
    {id:"transportation",name:"Transportation"},
    {id:"religion",name:"Religion"},
    {id:"publicService",name:"Public Services"},
    {id:"money",name:"Money"},
]

export const Checkpoint_Weights = [
    {id:"50",name:"50"},
    {id:"100",name:"100"},
    {id:"150",name:"150"},
    {id:"200",name:"200"},
    {id:"250",name:"250"},
    {id:"300",name:"300"},
    {id:"350",name:"350"},
    {id:"400",name:"400"},
]

export const generateUUID = () => {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
}

export const reportFlags = [
    { id:"chapra",name:"chapra",hideAttendanceField:true},
    { id: "oasys", name: "oasys", hideAttendanceField: true }
];

export const allMonths = {
    "01":"Jan",
    "02":"Feb",
    "03":"Mar",
    "04":"Apr",
    "05":"May",
    "06":"Jun",
    "07":"Jul",
    "08":"Aug",
    "09":"Sep",
    "10":"Oct",
    "11":"Nov",
    "12":"Dec"
}

export const restrictedMun = ["chapra","oasys"];
