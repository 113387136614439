import React, { useState } from 'react';
import { Color } from "./../../src/global/util";
import {
    Select
  } from "antd";
const { Option, OptGroup } = Select;

export default (props) => {
    let {s, a, c, b, t, u, bc, lh, to, ri, mt, mr, ml, mb, w, h, ph, boc,
        center, size, style, mode, data, onChange,value, ...rest} = props
    c = c || Color.themeFontColor;

    let st = { 
        ...{
          color : c,
          fontSize : s,
          lineHeight : lh,
          fontWeight: b ? 'bold' : undefined,
          textAlign : center ? 'center' : undefined,
          marginTop : mt ? mt : undefined,
          marginRight : mr ? mr : undefined,
          marginBottom : mb ? mb : undefined,
          marginLeft : ml ? ml : undefined,
          width : w ? w : 200,
          height : h ? h : 36,
          top: to ? to : undefined,
          right: ri ? ri : undefined,
          borderColor : boc ? boc : Color.themeFontColor,
          backgroundColor : bc ? bc : undefined,
          position: a ? "absolute" : undefined,
          textDecorationLine: u ? 'underline' : undefined,
        }, ...style};

  return (
    <Select
        size={size}
        mode={mode}
        placeholder={ph}
        value={value}
        allowClear
        dropdownStyle={{ borderColor : boc, borderWidth : 1 }}
        bordered
        onChange={onChange} style={st}
    >   {data.length > 0 &&
            data.map((item, index) => {
                return <Option key={index} style={{ height : h }} value={item}>{item}</Option>;
            })
        }
    </Select>
  );
}