/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable default-case */
/* eslint-disable no-duplicate-case */
import React, { Component, useEffect, useState, Suspense } from "react";
import "./App.css";
import { Layout } from "antd";
import Login from "./Containers/Login";
import Home from "./Containers/home/Home";
import DeleteUser from "./Containers/DeleteUser";
import WasteEstimation from "./Containers/WasteEstimation.js";
import Fuel from "./Containers/Fuel/index.js";
import SideBar from "./sidenavbar";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { AuthProvider, useUser, UserProvider } from "./Components/Auth";
import { LoadScript } from "react-google-maps";
import { Provider } from "react-redux";
import store from "./store";
import { isUserAllowed, getTabs } from "./API/api";
import { FormatDate } from "./Components/constants";
const FuelManagement = React.lazy(() => import("./Containers/FuelManagement"));
const Dashboard = React.lazy(() => import("./Containers/Dashboard"));
const Reports = React.lazy(() => import("./Containers/History/Reports.js"));
const Settings = React.lazy(() => import("./Containers/Settings"));
const SurveyForm = React.lazy(() => import("./Containers/SurveyForm"));
const Demo = React.lazy(() => import("./Containers/Survey"));
const Acknowledgements = React.lazy(() => import("./Containers/Acknowledge"));
const Checkpoints = React.lazy(() => import("./Containers/Checkpoints"));
const ReportsTable = React.lazy(() => import("./Containers/ReportsTable"));
const ReportsDemo = React.lazy(() => import("./Containers/ReportsDemo"));
const PrivateRoute = React.lazy(() => import("./Components/PrivateRoute"));
const Citizens = React.lazy(() => import("./ResidentsFiles/main.js"));
const Complaints = React.lazy(() => import("./Containers/Complaints"));
const Vehicles = React.lazy(() => import("./Containers/Vehicles"));
const Devices = React.lazy(() => import("./Containers/Devices"));
const Karmi = React.lazy(() => import("./Containers/Karmi"));
const Tasks = React.lazy(() => import("./Containers/Tasks"));
const Saathis = React.lazy(() => import("./SaathiFiles/saathiComponent"));
const Broadcast = React.lazy(() => import("./Containers/Broadcast"));
const Wards = React.lazy(() => import("./Containers/Wards"));
const Tags = React.lazy(() => import("./Containers/Tags"));
const ProcessingCenter = React.lazy(() => import("./Containers/ProcessingCenter"));
// const Payments = React.lazy(() => import("./Containers/Paymenpts"));
const Users = React.lazy(() => import("./Containers/Users"));
const Commercials = React.lazy(() => import("./CommercialFiles/index"));
const Suggestions = React.lazy(() => import("./Containers/Suggestions"));
const Feedbacks = React.lazy(() => import("./Containers/Feedbacks"));
const Ctpt = React.lazy(() => import("./Containers/Ctpt"));
const AppSettings = React.lazy(() => import("./Containers/AppSettings"));
const RouteManagement = React.lazy(() => import("./Containers/RouteManagement"));
const VehicleRemarks = React.lazy(() => import("./Containers/VehicleRemarks"));


const SpotFine = React.lazy(() => import("./Containers/SpotFine"));


function App() {
  const { user, loading, loaded } = useUser();
  const [permissionType, setPermissionType] = useState("");
  let [TabsData, setTabsData] = useState({});
  // useEffect(() => accessControl(localStorage.getItem("cui")), []);

  useEffect(() => {
    getTabsData();
    let todayDate = FormatDate(new Date());
    let dateFromStorage = JSON.parse(localStorage.getItem("todayDate"));
    if (!dateFromStorage) {
      localStorage.setItem("todayDate", JSON.stringify(todayDate));
    } else if (dateFromStorage != todayDate) {
      let keysToRemove = [
        "households", "commercial", "acknowledgement", "seggregation", "ctpt", "ctptAttandance",
        "feedBacks", "reportCheckpoints", "geohashCheckpoints", "onlineVehicles", "devices",
        "runnningVehicles", "standByVehicles", "remarkYesVehicles", "remarkNoVehicles",
        "complaints", "tasks", "saathi", "saathiAttended", "saathionlineNow", "saathionlineToday", "householdcount", "attendanceCount", "seggregatedCount", "acknowledgeCount"
      ];
      keysToRemove.forEach(k => localStorage.removeItem(k));
      localStorage.setItem("todayDate", JSON.stringify(todayDate));
    }
  }, []);


  const accessControl = (id) => {
    if (!id) return;
    isUserAllowed(id)
      .then((response) => {
        // console.log(response);
        let permission;
        if (response.is_readonly) {
          permission = "readOnly";
        } else if (response.is_admin) {
          permission = "isAdmin";
        }
        setPermissionType(permission);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const getTabsData = async () => {
    if (window.localStorage.mun) {
      if (window.localStorage.tabsData) {
        setTabsData(() => JSON.parse(window.localStorage.tabsData));
        return;
      }
      let response = await getTabs()
      setTabsData(response);
      window.localStorage.tabsData = JSON.stringify(response);
    }
  };

  const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
      <footer className="footer" style={{ padding: '10px 10px 0px 10px', bottom: '0', width: "100%", height: '40px', background: 'white' }}>
        <p>© {currentYear} Binimise Labs. All rights reserved.</p>
      </footer>
    );
  };

  const PathPicker = (tab) => {
    let isPresent = TabsData.length > 0 && TabsData.includes("Home");
    switch (tab) {
      case "Home":
        return isPresent ? "/" : "/home";

      case "FuelManagement":
        return "/fuelmanagement";

      case "WasteEstimation":
        return "/wasteEstimation";  

      case "Dashboard":
        return isPresent ? "/dashboard" : "/";

      case "History":
        return "/history";

      case "Checkpoints":
        return "/checkpoints";

        case "Fuel":
          return "/fuel";

      case "ProcessingCenter":
        return "/processingcenter";

      // case "Payments":
      //   return "/payments";

      case "Reports":
        return "/reports";

      case "ReportsDemo":
        return "/reportsdemo";

      case "Karmi":
        return "/karmi";

      case "Tasks":
        return "/tasks";

      case "Staff":
        return "/saathis";

      case "Residents":
        return "/citizens";

      case "Complaints":
        return "/complaints";

      case "SpotFine":
        return '/spotfine';

      case "Vehicles":
        return "/vehicles";

      case "Commercials":
        return "/commercials";

      case "Suggestions":
        return "/suggestions";

      case "Feedbacks":
        return "/feedbacks"

      case "Devices":
        return "/devices";

      case "Broadcast":
        return "/broadcast";

      case "Areas":
        return "/areas";

      case "Tags":
        return "/tags";

      case "Users":
        return "/users";

      case "Acknowledgements":
        return "/acknowledgements";

      case "Survey":
        return "/survey";

      case "SurveyForm":
        return "/surveyForm";

      case "Settings":
        return "/settings";

      case "AppSettings":
        return "/appsettings";

      case "Routes":
        return "/routemanagement";

      case "VehicleRemarks":
        return "/vehicleRemarks";


      case "CTPT":
        return "/ctpt";
    }
  };
  const ComponentPicker = (tab) => {
    switch (tab) {
      case "Home":
        return Home;

      case "FuelManagement":
        return FuelManagement;

      case "WasteEstimation":
        return WasteEstimation;  

      case "Dashboard":
        return Dashboard;

      case "History":
        return Reports;

      case "Checkpoints":
        return Checkpoints;

      case "ProcessingCenter":
        return ProcessingCenter;

      // case "Payments":
      //   return Payments;

      case "Fuel":
        return Fuel;

      case "Reports":
        return ReportsTable;

      case "ReportsDemo":
        return ReportsDemo;

      case "Karmi":
        return Karmi;

      case "Tasks":
        return Tasks;

      case "Staff":
        return Saathis;

      case "Residents":
        return Citizens;

      case "Complaints":
        return Complaints;

      case "SpotFine":
        return SpotFine;

      case "Vehicles":
        return Vehicles;

      case "Commercials":
        return Commercials;

      case "Suggestions":
        return Suggestions;

      case "Feedbacks":
        return Feedbacks;

      case "Devices":
        return Devices;

      case "Broadcast":
        return Broadcast;

      case "Areas":
        return Wards;

      case "Tags":
        return Tags;

      case "Users":
        return Users;

      case "Acknowledgements":
        return Acknowledgements;

      case "Survey":
        return Demo;

      case "SurveyForm":
        return SurveyForm;

      case "Settings":
        return Settings;

      case "AppSettings":
        return AppSettings;

      case "Routes":
        return RouteManagement;

      case "VehicleRemarks":
        return VehicleRemarks;
        
      case "CTPT":
        return Ctpt;
    }
  };

  if (loading) {
    return "loading...";
  }

  return (
    <Provider store={store}>
      <div className="App h-screen">
        <Suspense fallback={<div>Loading...</div>}>
          <BrowserRouter>
            <div className="h-full flex flex-row">
              {user && <SideBar />}
              <div className="h-full flex-1 overflow-auto">
                <Switch>
                  <div className="abcde" style={{ minHeight:"calc(100vh - 33px)" }}>
                  {loaded && !user && <Route exact path="/deleteUser" component={DeleteUser} />}
                  {loaded && !user && <Route exact to="/login" component={Login} />}
                  {TabsData &&
                    TabsData.length > 0 &&
                    ["Vehicles", "ReportsDemo","WasteEstimation", ...TabsData].map((tab, index) => {
                      return (
                        <PrivateRoute
                          key={index}
                          exact
                          path={PathPicker(tab)}
                          component={ComponentPicker(tab)}
                        />
                      );
                    })}
                  </div>
                </Switch>
                {user && <Footer key="2" />}
              </div>
            </div>
          </BrowserRouter>
        </Suspense>
      </div>
    </Provider>
  );
}

const GOOGLE_MAP_KEY = "AIzaSyAllyr9cA3d5Ne740l-fxaTQQN4TKwjEj0";

export default () => (
  <AuthProvider>
    <LoadScript googleMapsApiKey={GOOGLE_MAP_KEY} libraries={["drawing","places"]}>
      <App />
    </LoadScript>
  </AuthProvider>
);