import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import DailyFuel from './DailyFuel';
import CustomDateRangeFuel from './CustomDateRangeFuel';
import Header from '../../Components/Header';
import { getVehicles } from '../../API/api';

const { TabPane } = Tabs;

const Fuel = () => {
    const [vehicles, setVehicles] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchVehiclesData = async () => {
            try {
                setLoading(true);
                const currentTime = new Date().getTime();
                const vehiclesDataForLocal = localStorage.getItem("vehiclesDataForLocal");

                if (vehiclesDataForLocal) {
                    const storedData = JSON.parse(vehiclesDataForLocal);
                    const { timestamp, data } = storedData;

                    if (data && timestamp && currentTime - timestamp < 6 * 60 * 60 * 1000) {
                        setVehicles(data);
                        setLoading(false);
                        return;
                    }
                }

                const temp = {};
                const vehiclesDocs = (await getVehicles()).docs;
                vehiclesDocs?.forEach((eachDoc) => {
                    temp[eachDoc.data().device_id] = eachDoc.data();
                });
                const newVehiclesData = Object.values(temp);
                localStorage.setItem("vehiclesDataForLocal", JSON.stringify({ data: newVehiclesData, timestamp: currentTime }));
                setVehicles(newVehiclesData);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching vehicles data:", error);
                setLoading(false);
            }
        };

        fetchVehiclesData();
    }, []);

    return (
        <>
            <Header t={"FUEL REPORT"}></Header>
            <div style={{ paddingLeft: "10px" }}>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Daily Fuel Report" key="1">
                        <DailyFuel vehicles={vehicles} loading={loading} />
                    </TabPane>
                    <TabPane tab="Custom Dates Fuel Report" key="2">
                        <CustomDateRangeFuel vehicles={vehicles} loading={loading} />
                    </TabPane>
                </Tabs>
            </div>
        </>
    );
};

export default Fuel;
