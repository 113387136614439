import React, { useContext, useEffect, useState } from 'react';
import { Form, Input, Button, Checkbox, Row, Col, message, Spin, Space, Card, Progress, Layout } from 'antd';
import { login } from '../API/api';
import { withRouter, Redirect } from 'react-router-dom';
import { AuthContext } from '../Components/Auth';
import LoginImg from '../Utils/images/binimise_logo.png';
import { UserOutlined, LockOutlined, PropertySafetyFilled } from '@ant-design/icons';
import { getTenant } from "../API/api";
import { Color } from '../util';

const LoginComponent = ({ history }) => {
    const [arr, setarr] = useState([])
    const [loading, setLoading] = useState(false);
    const [progressPercentage, setProgressPercentage] = useState(0);
    useEffect(() => getTenantData(), [])
    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    };

    const tailLayout = {
        wrapperCol: {
            offset: 8,
            span: 16,
        },
    };


    const getTenantData = () => {

        getTenant().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {

                let obj = {
                    id: doc.id,
                    value: doc.data().value
                }
                arr.push(obj)

            })
            setarr(arr)
        })

    }
    const onFinish = async (values) => {

        const { mun } = values;
        const munID = mun;
        const isUserNameMatch = arr.some(item => item.value === munID);
        try {
            setLoading(true)
            values.username = values.username.trim();
            arr != undefined && arr.length > 0 && arr.map((each) => {
                if (each.id === values.mun) {
                    return values.mun = each.value
                }
            })
            let loggedInData = await login(values);
            if (loggedInData.errorCode) {
                message.error({ content: loggedInData.errorMessage, duration: 4 });
            } else {
                localStorage.setItem('mun', values.mun);
                localStorage.setItem("cui", loggedInData.cui);
                history.replace("/", { cui: loggedInData.cui });
                window.location.reload();
                setLoading(false);
            }
        } catch (error) {
            if (error.message === "Cannot read properties of undefined (reading 'errorCode')" && !isUserNameMatch) {
                alert("Entered Tenant ID is not matched. Please click on logout and enter correct credentials.")
            } else if (error.message === "The password is invalid or the user does not have a password.") {
                alert("The password is invalid. Please enter correct password.")

            } else if (error.message === "There is no user record corresponding to this identifier. The user may have been deleted.") {
                alert("There is no user record corresponding to the entered username. The user may have been deleted, or please enter correct credentials.")

            } else {
                alert("An Error Ocurred. Please click on logout and enter valid credentials");
            }
            setLoading(false);
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    const { currentUser } = useContext(AuthContext);

    if (loading) {
        return (
            <Layout style={{ minHeight: "100vh", display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
                <Space size="large" className="loader">
                    <Spin size="large" />
                </Space>
            </Layout>
        )
    }

    if (currentUser) {
        return <Redirect exact to="/" />
    }

    return (
        <div className="container">
            <users message="data" />
            <div className="ant-row">
                <div className="ant-col">
                    <div class="flex justify-center align-center">
                        <img src={LoginImg} className="" style={{ width: 200 }} />
                    </div>
                    <h1 className="login-text" style={{ color: Color.ThemeColor }}>Log In</h1>
                    <Form
                        name="normal_login"
                        style={{ width: '100%', height: '100%', paddingTop: 20 }}
                        className="login-form"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label="Username"
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Username!',
                                },
                            ]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} size="large" placeholder="Enter Username" />
                        </Form.Item>
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Password!',
                                },
                            ]}
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                size="large"
                                type="password"
                                placeholder="Enter Password"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Tenant ID"
                            name="mun"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your mun !',
                                },
                            ]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} size="large" placeholder="Enter Tenant ID" />
                        </Form.Item>
                        <Form.Item>
                            <Form.Item name="remember" valuePropName="checked" noStyle>
                                <Checkbox>Remember me</Checkbox>
                            </Form.Item>
                        </Form.Item>
                        <Form.Item style={{ paddingTop: 10 }}>
                            <Button type="primary"
                                style={{ backgroundColor: Color.ThemeColor, borderColor: Color.ThemeColor }} htmlType="submit" className="btn">
                                Log in
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default withRouter(LoginComponent);
