/* eslint-disable no-unused-vars */
/* eslint-disable default-case */
/* eslint-disable no-duplicate-case */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component, useEffect, useState } from "react";
import moment from "moment";
import { Color } from "./../src/global/util";
import { Layout, Spin, Space, Menu, message } from "antd";
import BinimiseIcon from "./logo.svg";
import {
  AiOutlineDashboard,
  AiOutlineUser,
  AiOutlineLogout,
  AiOutlineFilePdf as ReportIcon,
  AiOutlineCompass as DriverIcon,
  AiOutlineBook,
  AiOutlineCar,
  AiOutlineMobile,
  AiOutlineAreaChart,
  AiOutlineUsergroupAdd,
  AiOutlineHistory,
  AiOutlineMessage,
  AiFillCarryOut,
  AiOutlineUpCircle,
  AiOutlineSetting,
  AiOutlineVerified,
  AiFillPieChart,
  AiOutlineBank,
  AiOutlineSmile,
  AiFillHome,
  AiOutlineExperiment
} from "react-icons/ai";
import { NavLink as Link, withRouter, Route } from "react-router-dom";
import {
  logout,
  isUserAllowed,
  getTabs,
  getSettingsMsg,
  getAllUsers,
  getConsoleUsers
} from "./API/api";
import { MenuOutlined } from "@ant-design/icons";
import { useUser, UserConsumer, AuthContext } from "./Components/Auth";
import NotFoundComponent from "./Components/NotFound";
import { Decode } from "./Components/constants";
const { Sider } = Layout;
const MenuItem = ({ icon, label, ...rest }) => (
  <Link
    className="hover:text-black hover:bg-gray-100 px-4 py-3 block text-l flex items-center gap-2"
    activeClassName="bg-gray-100 hover:bg-gray-100"
    exact
    style={{ color: Color.themeFontColor }}
    {...rest}
  >
    <span style={{ fontSize: 18 }}>{icon}</span>
    <span className="ml-1 w-32 text-base text-left lg:block" style={{ color: Color.themeFontColor }}>
      {label}
    </span>
  </Link>
);

class Sidebar extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showSidebar: true,
      TabsData: [],
      MuncipalityImage: "",
      UsersData: [],
      permissionType: "isAdmin"
    };
  }

  onLogout = async () => {
    this.setState({ loading: true });
    try {
      await logout();
      this.props.history.push("login");
      localStorage.clear();
      this.setState({ loading: false });
      window.location.reload();
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  };

  onToggle = () => {
    this.setState({
      showSidebar: !this.state.showSidebar,
    });
  };

  getAccessControl() {
    // console.log(window.localStorage.cui)
    isUserAllowed(localStorage.getItem("cui"))
      .then((response) => {
        let permission;
        if (response.is_readonly) {
          permission = "readOnly";
        } else if (response.is_admin) {
          permission = "isAdmin";
        }
        this.setState({
          permissionType: permission,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  }

  getTabsData() {
    if (window.localStorage.mun) {
      getTabs().then((response) => {
        this.setState({
          TabsData: response,
        });
      });
    }
  }

  getSettingsMsgData() {
    if (window.localStorage.mun) {
      getSettingsMsg().then((response) => {
        this.setState({
          MuncipalityImage: response,
        });
      });
    }
  }

  componentDidMount() {
    // this.getAccessControl();
    this.getTabsData();
    this.getSettingsMsgData();
    this.getAllUsersData();
  }

  getAllUsersData() {
    let data = [];
    if (window.localStorage.mun) {
      getConsoleUsers()
        .then((doc) => {
          let docData = doc.data();
          data.push({
            cui: doc.id,
            perms: docData.perms,
          });

          this.setState({
            UsersData: data,
            loading: false,
          });
        })
        .catch((e) => message.error({ content: e, duration: 2 }))
    }
  }

  pathPicker(tab) {
    const { TabsData } = this.state;
    let isPresent = TabsData.length > 0 && TabsData.includes("Home");
    switch (tab) {
      case "Home":
        return isPresent ? "/" : "/home";

      case "Dashboard":
        return isPresent ? "/dashboard" : "/";

      case "History":
        return "/history";
        
      case "Checkpoints":
        return "/checkpoints";

      case "ProcessingCenter":
        return "/processingcenter";

      case "Reports":
        return "/reports";

      case "Karmi":
        return "/karmi";

      case "Tasks":
        return "/tasks";

      case "Staff":
        return "/saathis";

      case "Residents":
        return "/citizens";

      case "Complaints":
        return "/complaints";

      case "SpotFine":
        return "/spotfine";

      case "Vehicles":
        return "/vehicles";

      case "Commercials":
        return "/commercials";

      case "Devices":
        return "/devices";

      case "Broadcast":
        return "/broadcast";

      case "Areas":
        return "/areas";

      case "Tags":
        return "/tags";

      case "Payments":
        return "/payments";

      case "Users":
        return "/users";

      case "Acknowledgements":
        return "/acknowledgements";

      case "Survey":
        return "/survey";

      case "SurveyForm":
        return "/SurveyForm";

      case "Settings":
        return "/settings";

      case "AppSettings":
        return "/appsettings";

      case "Routes":
        return "/routemanagement"

      case "Suggestions":
        return "/suggestions";

      case "Feedbacks":
        return "/feedbacks";

      case "CTPT":
        return "/ctpt";

      case "FuelManagement":
        return "/fuelmanagement";

      case "Fuel":
        return "/fuel";

      case "SpotFine":
        return "/spotFine";
    }
  }
  iconPicker(tab) {
    switch (tab) {
      case "Home":
        return <AiFillHome />;

      case "Dashboard":
        return <AiOutlineDashboard />;

      case "History":
        return <AiOutlineHistory />;

      case "Checkpoints":
        return <AiOutlineUpCircle />;

      case "ProcessingCenter":
        return <AiOutlineUpCircle />;

      case "Reports":
        return <ReportIcon />;

      case "Karmi":
        return <DriverIcon />;

      case "Tasks":
        return <AiFillCarryOut />;

      case "Staff":
        return <AiOutlineUser />;

      case "Residents":
        return <AiFillHome />;

      case "Commercials":
        return <AiOutlineUsergroupAdd />;

      case "Complaints":
        return <AiOutlineBook />;

      case "SpotFine":
        return <AiOutlineBank />;

      case "Vehicles":
        return <AiOutlineCar />;

      case "Devices":
        return <AiOutlineMobile />;

      case "Broadcast":
        return <AiOutlineMessage />;

      case "Areas":
        return <AiOutlineAreaChart />;

      case "Tags":
        return <AiOutlineAreaChart />;

      case "Payments":
        return <AiOutlineBank />;

      case "Users":
        return <AiOutlineUser />;

      case "Acknowledgements":
        return <AiOutlineVerified />;

      case "Survey":
        return <AiFillPieChart />;

      case "SurveyForm":
        return <AiFillPieChart />;

      case "Settings":
        return <AiOutlineSetting />;

      case "AppSettings":
        return <AiOutlineSetting />;

      case "Suggestions":
        return <AiOutlineSmile />;

      case "Feedbacks":
        return <AiOutlineSmile />

      case "CTPT":
        return <AiFillPieChart />;

      case "Routes":
        return <AiOutlineCar />;

      case "FuelManagement":
        return <AiOutlineExperiment />;

      case "Fuel":
        return <AiOutlineExperiment />;
        
      case "SpotFine":
        return <AiOutlineBank />;
    }
  }

  render() {
    const { loading, showSidebar, permissionType, TabsData, UsersData, MuncipalityImage } = this.state;
    let data = [];
    let cui = localStorage.getItem("cui");

    UsersData != undefined &&
      UsersData.length > 0 &&
      UsersData.map((each) => {
        if (cui === each.cui && each.perms === undefined) {
          if (window.localStorage.mun) {
            data = TabsData;
          }
        } else if (cui == each.cui) {
          if (each.perms.is_view_dashboard) {
            data.push("Dashboard");
          }

          if (each.perms.is_view_history) {
            data.push("History");
          }

          if (each.perms.is_view_Checkpoints) {
            data.push("Checkpoints");
          }

          if (each.perms.is_view_reports) {
            data.push("Reports");
          }

          if (each.perms.is_view_karmi) {
            data.push("Karmi");
          }

          if (each.perms.is_view_tasks) {
            data.push("Tasks");
          }

          if (each.perms.is_view_Saathi) {
            data.push("Saathi");
          }

          if (each.perms.is_view_Citizen) {
            data.push("Residents");
          }

          if (each.perms.is_view_Commercial) {
            data.push("Commercials");
          }

          if (each.perms.is_view_Payments) {
            data.push("Payments");
          }

          if (each.perms.is_view_complaint) {
            data.push("Complaints");
          }

          if (each.perms.is_view_vehicles) {
            data.push("Vehicles");
          }

          if (each.perms.is_view_center) {
            data.push("ProcessingCenter");
          }

          if (each.perms.is_view_device) {
            data.push("Devices");
          }

          if (each.perms.is_view_broadcast) {
            data.push("Broadcast");
          }

          if (each.perms.is_view_Acknowledge) {
            data.push("Acknowledgements");
          }

          if (each.perms.is_view_Settings) {
            data.push("Settings");
          }

          if (each.perms.is_view_ward) {
            data.push("Areas");
          }

          if (each.perms.is_view_tag) {
            data.push("Tags");
          }

          if (each.perms.is_view_suggestions) {
            data.push("Suggestions");
          }

          if (each.perms.is_view_feedbacks) {
            data.push("Feedbacks");
          }

          if (each.perms.is_view_ctpt) {
            data.push("CTPT");
          }

          if (each.perms.is_view_routes) {
            data.push("Routes");
          }
          
          if (each.perms.is_view_home) {
            data.push("Home");
          }
        }
      });

    if (loading) {
      return;
    }

    return (
      <div id="sidebar" className="flex flex-col h-full" style={{ borderRightWidth: 1, borderColor: Color.themeFontColor, display: "flex" }}>
        <div className="p-3 h-14 cursor-pointer" style={{ height: 180, width: 220 , display:"flex", justifyContent:"center"}}>
          <img
            className="h-8 lg:block"
            src={MuncipalityImage ? MuncipalityImage : BinimiseIcon}
            style={{
              height: 150,
              backgroundColor: window.localStorage.mun === "oasys" ? "lightgray" : "transparent",
            }}
          />
        </div>
        <div className="flex flex-col flex-1 overflow-auto">
          {data.map((tab, indx) => {
            return (
              <MenuItem
                key={indx}
                to={this.pathPicker(tab)}
                label={tab}
                icon={this.iconPicker(tab)}
              />
            );
          })}

          <MenuItem
            onClick={this.onLogout}
            to="/logout"
            label="Logout"
            icon={<AiOutlineLogout />}
          />
        </div>
      </div>
    );
  }
}
const SidebarContainer = (props) => {
  const { user } = useUser();
  const [permissionType, setPermissionType] = useState("isAdmin");
  const accessControl = (id) => {
    isUserAllowed(id)
      .then((response) => {
        let permission;
        if (response.is_readonly) {
          permission = "readOnly";
        } else if (response.is_admin) {
          permission = "isAdmin";
        }
        setPermissionType(permission);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  useEffect(() => accessControl(user.uid), []);
  return <Sidebar permissionType={permissionType} {...props} />;
};
export default withRouter(Sidebar);