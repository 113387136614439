import { 
    TEST_SAGA,
    UPDATE_DATA
} from "./constants";

let reducer = {

     // errorModal
     errorModalInfo : {
        showModal : false,
        title : "",
        message : "",
        buttonText : "",
        onClose : "",
        isError: false,
    },

    // confirmModal
    confirmModalInfo : {
        showModal : false,
        title : "",
        message : "",
        primaryText : "",
        primaryAction : "",
        secondaryText : "",
        secondaryAction : ""
    },

    // carousel
    carouselData : {
        show : false,
        imageList : []
    },

    updateUserInfoFlag : false,

    // loading
    loading : {
        show : 0
    },

    camera : {
        show : false
    },

    sidebar : {
        show : false
    },

    phoneNumber: "",

    idToken: "",

    gymDetails: {},

    dateTimePickerParam: {
        show: false,
        date: new Date(),
    },
    
    showExplain: false,

    exercises : {},

    isAddWorkout: false,

    workouts: {},

    workoutPlan: {},

    keyToAddWorkout: "",
    saathiArray : [],
    wardsArray : [],
    residentsArray : [],
    staffMetaArray : {},
};

export default (state = reducer, action) => {
    switch (action.type) {
        case TEST_SAGA:
            return Object.assign({}, state, { test: action.payload });
        case UPDATE_DATA:
            return { ...state, ...action.data };
        default:
            return state;
    }
};