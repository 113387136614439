import moment from 'moment';
const CryptoJS = require("crypto-js");
const HASH_KEY = "6fb74b35af6ce0";

export function DateFormat(date) {
  return moment(date).format('YYYY-MM-DD')
}

export function DisplayDate(date) {
  return moment(date).format('YYYY/MM/DD')
}

export function FormatDate(date) {
 // console.log(date,moment(date).format('DD-MM-YYYY'))
  return moment(date).format('DD-MM-YYYY')
}

export function Format_Date(date) {
  return moment(date).format('DD-MM-YYYY HH:MM')
}


export function Encode(decodedString) {
  return window.btoa(
    encodeURIComponent(decodedString).replace(
      /%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
        return String.fromCharCode('0x' + p1)
      },
    ),
  )
}

export function Decode(encodedString) {
  return decodeURIComponent(
    window
      .atob(encodedString)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )
}

export function decryptString(str) {
  try {
    let res = CryptoJS.AES.decrypt(str, HASH_KEY).toString(CryptoJS.enc.Utf8);
    if(!res) return str;
    return res;
  } catch(err){}
  return str;
}

export const formatDateTime = (timestamp) => {
  if (timestamp && typeof timestamp === "object" && timestamp.seconds && timestamp.nanoseconds) {
    const milliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1e6;
    const dateObject = new Date(milliseconds);
    return dateObject.toLocaleString();
  }
  if (timestamp && typeof timestamp === "object" && timestamp._seconds && timestamp._nanoseconds) {
    const milliseconds = timestamp._seconds * 1000 + timestamp._nanoseconds / 1e6;
    const dateObject = new Date(milliseconds);
    return dateObject.toLocaleString();
  } else if (timestamp && typeof timestamp === "string") {
    if (timestamp.includes("T") && !timestamp.includes("UTC")) {
      const date = new Date(timestamp);
      return `${date.toLocaleDateString('en-IN', {
        timeZone: 'Asia/Kolkata',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })}, ${date.toLocaleTimeString('en-IN', {
        timeZone: 'Asia/Kolkata',
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })}`;
    } else if (timestamp.includes("at") && !timestamp.includes("UTC")) {
      const timestampParts = timestamp.split(' at ');
      const dateString = timestampParts[0];
      const timeString = timestampParts[1];
      const date = new Date(dateString);
      const time = timeString
        .replace(/[AaPp][Mm]/, '')
        .trim();
      const [hours, minutes, seconds] = time.split(':').map(part => parseInt(part, 10));
      const includesDot = timestamp.includes('.');
      let modifiedTimestamp;

      if (includesDot) {
        date.setHours(hours, minutes, seconds);
      } else {
        date.setHours(hours + 5, minutes + 30, seconds);
      }

      modifiedTimestamp = `${date.toLocaleDateString('en-IN', {
        timeZone: 'Asia/Kolkata',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })}, ${date.toLocaleTimeString('en-IN', {
        timeZone: 'Asia/Kolkata',
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })}`;

      return modifiedTimestamp;
    } else if (timestamp.includes("at") && timestamp.includes("UTC")) {
      const modifiedText = timestamp.replace(" at", "");
      const timestamptodisplay = new Date(modifiedText);
      const istTimestamp = new Date(timestamptodisplay.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));
      const istTimeString = istTimestamp.toLocaleString();
      return istTimeString
    } else {
      return "N/A";
    }
  }
};
