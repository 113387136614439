import React, { useState, useEffect } from 'react';
import { DatePicker, Spin, message, Table, Button, Menu, Dropdown } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { getReports } from '../../API/api';
import ExcelJS from 'exceljs';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { DownloadOutlined } from '@ant-design/icons';

const DailyFuel = ({ vehicles }) => {
    const [selectedDate, setSelectedDate] = useState(moment());
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [completeDataSource, setCompleteDataSource] = useState([]);
    const [totalFuelQuantity, setTotalFuelQuantity] = useState(0);
    const [fuelType, setFuelType] = useState("All");
    const [lang,setLang] = useState(window.localStorage.mun == 'jagdalpur' ? 'hindi' : 'eng');
    const handleDateChange = (date, dateString) => {
        setSelectedDate(moment(dateString));
    };
    console.log(lang)
    const disabledDate = current => {
        return current && current > moment().endOf('day');
    };

    useEffect(() => {
        getDailyFuelTable();
    }, [selectedDate, vehicles]);


    const getDailyFuelTable = async () => {
        try {
            setLoading(true);
            const date_each = selectedDate.format('DD-MM-YYYY');
            const allReports = await Promise.all(vehicles.map(vehicle =>
                getReports(date_each, vehicle.device_id, vehicle.ward_id, vehicle.type, vehicle.order)
            ));
            if (allReports.length === 0) {
                setDataSource([]);
                setTotalFuelQuantity(0);
                setLoading(false);
                return;
            }
            let data = [];
            allReports.forEach(reportsData => {
                reportsData.forEach(doc => {
                    const docData = doc.data();
                    if (vehicles.some(vehicle => vehicle.device_id === docData.device_id)) {
                        let vechileDetail = vehicles.find(vehicle => vehicle.device_id === docData.device_id);
                        if (docData.vehicle_name && docData.vehicle_num) {
                            docData.idle_time = Math.abs(docData.idle_time);
                            if (docData.distance)
                                docData.distance = docData.distance.toFixed(2);
                            data.push({
                                vehicle_num: docData.vehicle_num || "",
                                vehicle_name: docData.vehicle_name || "",
                                phone_num: docData.phone_num || "",
                                fuel_quantity: docData.quantity || "0",
                                fuel_type: vechileDetail.fuel_type || "",
                                distance: docData.distance || '0',
                                engine_hours: docData.engine_hours || '0',
                                duration: docData.duration || "",
                                max_speed: docData.max_speed || "0",
                                ward_id: docData.ward_id || "",
                                minDistance: docData.minDistance || "",
                                maxDistance: docData.maxDistance || "",
                                device_id_now: docData.device_id,
                            });
                        }
                    }
                });
            });
            const processedData = vehicles.map((vehicle, index) => {
                let dis = 0;
                let eng_hr = 0;
                let speed = [0];
                const vehicleData = [];
                data.forEach(val => {
                    if (val.device_id_now === vehicle.device_id) {
                        dis += parseFloat(val.distance);
                        eng_hr += parseFloat(val.engine_hours);
                        speed.push(parseFloat(val.max_speed));
                        vehicleData.push(val);
                    }
                });
                if (window.localStorage.mun == "dehri") {
                    eng_hr = eng_hr * 1000;
                }
                const max_spd = Math.max(...speed);
                const en_minutes = Math.floor((eng_hr / (1000 * 60)) % 60);
                const en_hours = Math.floor((eng_hr / (1000 * 60 * 60)) % 24);
                const t_hours = en_hours < 10 ? "0" + en_hours : en_hours;
                const t_minutes = en_minutes < 10 ? "0" + en_minutes : en_minutes;
                const en_time = t_hours + ":" + t_minutes;
                return {
                    device: vehicle.device_id,
                    vehicle_num: vehicle.vehicle_id || "N/A",
                    vehicle_name: vehicle.vehicle_name || "N/A",
                    phone_num: vehicle.phone_num || "N/A",
                    fuel_quantity: vehicleData.length > 0 ? vehicleData[0].fuel_quantity : "0",
                    fuel_type: vehicle.fuel_type || "N/A",
                    distance: dis.toFixed(2) || "N/A",
                    engine_hours: en_time || "N/A",
                    duration: vehicleData.length > 0 ? vehicleData[0].duration : "N/A",
                    max_speed: max_spd || "N/A",
                    ward_id: vehicle.ward_id.join(', ') || "N/A",
                    minDistance: vehicle.minDistance || "N/A",
                    maxDistance: vehicle.maxDistance || "N/A",
                };
            });
            let sortedData = processedData.sort((a, b) => {
                if (a.vehicle_num < b.vehicle_num) return -1;
                if (a.vehicle_num > b.vehicle_num) return 1;
                return 0;
            });
            const totalQuantity = processedData.reduce((accumulator, currentValue) => {
                const fuelQuantity = parseFloat(currentValue.fuel_quantity);
                return accumulator + fuelQuantity;
            }, 0);
            sortedData = sortedData.filter(item => parseFloat(item.fuel_quantity) !== 0);

console.log(sortedData);
console.log('Total Quantity:', totalQuantity);
            setTotalFuelQuantity(totalQuantity);
            const dataWithSerialNumbers = sortedData.map((item, index) => {
                return { serialNo: index + 1, ...item, };
            });
            setCompleteDataSource(dataWithSerialNumbers);
            filterDataByFuelType(fuelType);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching reports: ", error);
            setLoading(false);
            message.error("An error occurred while fetching reports.");
        }
    };

    const downloadPDF = async (data) => {
        try {
            const modifiedData = data.map(item => ({
                serialNo: item.serialNo,
                vehicle_num: item.vehicle_num,
                // vehicle_name: item.vehicle_name,
                // phone_num: item.phone_num,
                // ward_id: item.ward_id,
                fuel_quantity: item.fuel_quantity,
            }));
            let tableStartY = 15;
            const mun =
                window.localStorage.mun.charAt(0).toUpperCase() +
                window.localStorage.mun.slice(1);
            let words = `Fuel Daily Report - ${fuelType == 'All' ? '' : fuelType}`
            let formattedDate = selectedDate.format("YYYY-MM-DD");
            const headingText = `${mun} ${words} ${formattedDate}`;
            const doc = new jsPDF("landscape");
            doc.setFontSize(18);
            doc.text(10, 10, headingText);
            tableStartY = 15;
            doc.setFontSize(12);
            const headers = Object.keys(modifiedData[0]);
            const columnLabels = {
                serialNo: "Sl No.",
                device: "Device ID",
                vehicle_num: "Vehicle Number",
                vehicle_name: "Vehicle Name",
                phone_num: "Phone Number",
                fuel_quantity: "Fuel Quantity",
                distance: "Distance",
                engine_hours: "Engine Hours",
                duration: "Duration",
                max_speed: "Max Speed",
                ward_id: "Assigned Area",
                minDistance: "Min Distance",
                maxDistance: "Max Distance",
            };
            const columnWidths = headers.map(() => "auto");
            const columnsPerPage = 17;
            const totalPages = Math.ceil(headers.length / columnsPerPage);
            const commonStyles = {
                lineWidth: 0.1,
                fontSize: 8,
                cellPadding: 2,
            };
            doc.setFontSize(8);
            doc.setTextColor(0, 0, 0);
            doc.setFont("helvetica", "bold");
            for (let page = 0; page < totalPages; page++) {
                const startIndex = page * columnsPerPage;
                const endIndex = Math.min(
                    startIndex + columnsPerPage,
                    headers.length
                );
                const pageHeaders =
                    page === 0
                        ? headers
                            .slice(startIndex, endIndex)
                            .map((key) => columnLabels[key] || key)
                        : [
                            headers[0],
                            ...headers
                                .slice(startIndex, endIndex)
                                .map((key) => columnLabels[key] || key),
                        ];
                const pageColumnKeys =
                    page === 0
                        ? headers.slice(startIndex, endIndex)
                        : [
                            headers[0],
                            ...headers.slice(startIndex, endIndex),
                        ];
                const pageTableRows = [pageHeaders];
                modifiedData.forEach((row) => {
                    const rowData = pageColumnKeys.map((header) => row[header]);
                    pageTableRows.push(rowData);
                });
                doc.autoTable({
                    startY: tableStartY,
                    head: [pageTableRows[0]],
                    body: pageTableRows.slice(1),
                    theme: "grid",
                    styles: commonStyles,
                    headStyles: {
                        fillColor: [245, 245, 245],
                        textColor: [0, 0, 0],
                        fontStyle: "bold",
                        fontSize: 10,
                    },
                });
                if (page < totalPages - 1) {
                    doc.addPage();
                }
            }
            const fileName = `${headingText}.pdf`;
            doc.save(fileName);
        } catch (error) {
            console.log("Error in downloadPDF: ", error)
            message.error({
                content:
                    "Error in downloading pdf or there is no data available at the moment to download.",
                duration: 5,
            });
        }
    }

    const downloadExcel = async (data) => {
        try {
            const modifiedData = data.map(item => ({
                serialNo: item.serialNo,
                vehicle_num: item.vehicle_num,
                // vehicle_name: item.vehicle_name,
                // phone_num: item.phone_num,
                // ward_id: item.ward_id,
                fuel_quantity: item.fuel_quantity,
            }));
            const headerMapping = {
                serialNo: "Sl No.",
                device: "Device ID",
                vehicle_num: "Vehicle Number",
                vehicle_name: "Vehicle Name",
                phone_num: "Phone Number",
                fuel_quantity: "Fuel Quantity",
                distance: "Distance",
                engine_hours: "Engine Hours",
                duration: "Duration",
                max_speed: "Max Speed",
                ward_id: "Assigned Area",
                minDistance: "Min Distance",
                maxDistance: "Max Distance",
            };
            const mun =
                window.localStorage.mun.charAt(0).toUpperCase() +
                window.localStorage.mun.slice(1);
            let words = `Fuel Daily Report - ${fuelType == 'All' ? '' : fuelType}`
            let formattedDate = selectedDate.format("YYYY-MM-DD");
            const headingText = `${mun} ${words} ${formattedDate}`;
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet(`${headingText}`);
            const headers = Object.keys(modifiedData[0]);
            const headerRow = headers.map((header) => headerMapping[header] || header);
            worksheet.addRow(headerRow).eachCell((cell) => {
                cell.font = { bold: true };
            });
            modifiedData.forEach((row) => {
                const rowData = headers.map((header) => row[header]);
                worksheet.addRow(rowData);
            });
            worksheet.columns.forEach((column) => {
                let maxLength = 0;
                column.eachCell({ includeEmpty: true }, (cell) => {
                    const length = cell.value ? cell.value.toString().length : 0;
                    if (length > maxLength) {
                        maxLength = length;
                    }
                });
                column.width = maxLength < 10 ? 10 : maxLength + 2;
            });
            const blob = await workbook.xlsx.writeBuffer();
            const blobObject = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const fileName = `${headingText}.xlsx`;
            const url = window.URL.createObjectURL(blobObject);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.log("Error in downloadExcel: ", error)
            message.error({ content: "Error in downloading excel or there is no data available at the moment to download.", duration: 5 })
        }
    }

    const handleDownload = (type) => {
        if (dataSource.length == 0) {
            message.error('No Data Available To Download');
            return;
        }
        if (type === 'pdf') {
            message.info('PDF download initiated');
            downloadPDF(dataSource)
        } else if (type === 'excel') {
            message.info('Excel download initiated');
            downloadExcel(dataSource)
        }
    };

    const filterDataByFuelType = (type) => {
        if (type !== 'All') {
            let filteredData = completeDataSource
            .filter(data => data.fuel_type === type.toLowerCase())
            filteredData = filteredData.map((data, index) => {
                return { ...data, serialNo: index + 1 };
            });
            setDataSource(filteredData)
                
        }
        else {
            setDataSource(completeDataSource)
        }
    }

    const handleFuelType = (type) => {
        setFuelType(type);
        filterDataByFuelType(type);
    }

    const menu = () => {
        return (
            <Menu onClick={e => handleDownload(e.key)}>
                <Menu.Item key="pdf" style={{ display: 'flex', alignItems: 'center' }}>
                    <DownloadOutlined style={{ verticalAlign: 'middle', marginRight: '8px' }} />
                    PDF
                </Menu.Item>
                <Menu.Item key="excel" style={{ display: 'flex', alignItems: 'center' }}>
                    <DownloadOutlined style={{ verticalAlign: 'middle', marginRight: '8px' }} />
                    Excel
                </Menu.Item>
            </Menu>
        );
    };
    
    const LanguageChange = () => {
        if(lang == 'eng'){
            setLang('hindi')
        }
        else{
            setLang('eng')
        }
    }
    const fuelTypeMenu = () => {
        return (
            <Menu onClick={e => handleFuelType(e.key)}>
                <Menu.Item key="All" style={{ display: 'flex', alignItems: 'center' }}>
                    {lang=='eng' ? 'All' : 'सभी'}
                </Menu.Item>
                <Menu.Item key="Petrol" style={{ display: 'flex', alignItems: 'center' }}>
                {lang=='eng' ? 'Petrol' : 'पेट्रोल'}
                </Menu.Item>
                <Menu.Item key="Diesel" style={{ display: 'flex', alignItems: 'center' }}>
                    {lang=='eng' ? 'Diesel' : 'डीज़ल'}
                </Menu.Item>
            </Menu>
        );
    };

    const columns = [
        { title: lang=='eng' ? 'Serial No.' : 'क्रमांक', dataIndex: 'serialNo', key: 'serialNo' },
        { title: lang=='eng' ? 'Vehicle Number': 'वाहन संख्या', dataIndex: 'vehicle_num', key: 'vehicle_num' },
        // { title: lang=='eng' ? 'Vehicle Name' : 'वाहन का नाम', dataIndex: 'vehicle_name', key: 'vehicle_name' },
        // { title: lang=='eng' ? 'Phone Number' : 'फ़ोन नंबर', dataIndex: 'phone_num', key: 'phone_num' },
        { title: lang=='eng' ? 'Fuel Quantity' : 'ईंधन की मात्रा', dataIndex: 'fuel_quantity', key: 'fuel_quantity' },
        // { title: 'Distance', dataIndex: 'distance', key: 'distance' },
        // { title: 'Engine Hours', dataIndex: 'engine_hours', key: 'engine_hours' },
        // { title: 'Duration', dataIndex: 'duration', key: 'duration' },
        // { title: 'Max Speed', dataIndex: 'max_speed', key: 'max_speed' },
        // { title: lang=='eng' ? 'Assigned Area' : 'निर्धारित क्षेत्र', dataIndex: 'ward_id', key: 'ward_id' },
        // { title: 'Min Distance', dataIndex: 'minDistance', key: 'minDistance' },
        // { title: 'Max Distance', dataIndex: 'maxDistance', key: 'maxDistance' },
    ];
    return (
        <div style={{ position: 'relative', marginRight: "10px" }}>
            <Button onClick={LanguageChange} style={{position:'absolute', top:"-60px", right:10}}>
                {lang}
                </Button>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
                <p style={{ marginBottom: "0" }}>{lang == 'eng' ? 'Selected Date :':'चयनित तिथि :'} <strong>{selectedDate.format("YYYY-MM-DD")}</strong></p>
                <p style={{ marginBottom: "0" }}>{lang == 'eng' ? 'Total Fuel Quantity:':' कुल ईंधन मात्रा :'} <strong>{isNaN(totalFuelQuantity) ? '0' : totalFuelQuantity}</strong></p>
                <DatePicker
                    defaultValue={selectedDate}
                    onChange={handleDateChange}
                    disabledDate={disabledDate}
                    style={{ width: "120px" }}
                />
                <Dropdown overlay={fuelTypeMenu}>
                    <Button>{fuelType == 'All' ? lang == 'eng' ? 'FuelType':' ईंधन प्रकार' : fuelType}</Button>
                </Dropdown>
                <Dropdown overlay={menu}>
                    <Button>{lang == 'eng' ? 'Download' : 'डाउनलोड'}</Button>
                </Dropdown>
            </div>
            <div style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
                <Spin spinning={loading} size="large">
                    <Table dataSource={dataSource} columns={columns} pagination={false} />
                </Spin>
            </div>
        </div>
    );
};

DailyFuel.propTypes = {
    vehicles: PropTypes.array.isRequired,
};

export default DailyFuel;