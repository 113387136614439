import * as firebase from 'firebase'
const app = firebase.initializeApp({
  apiKey: "AIzaSyDGujzrdjwGvcq2FbsdEUV4t_-MYhL9dfQ",
  authDomain: "binimise-v1.firebaseapp.com",
  databaseURL: "https://binimise-v1.firebaseio.com",
  projectId: "binimise-v1",
  storageBucket: "binimise-v1.appspot.com",
  messagingSenderId: "149629247619",
  appId: "1:149629247619:web:c26ab6066e1acf07933bb6",
  measurementId: "G-H51MQTTTHS",
  GOOGLE_MAP_KEY: "AIzaSyAllyr9cA3d5Ne740l-fxaTQQN4TKwjEj0",
});
export default app;
