import React, { useEffect, useState } from "react";
import firebaseApp from "../API/base";
import { getUserConfig } from "../API/api";
import useSWR from "swr";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (window.localStorage.userConfig) {
      setUser( JSON.parse(window.localStorage.userConfig));
      setLoaded(true);
      return;
    }
    setLoading(true)
    firebaseApp.auth().onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
      setLoaded(true);
    });
  }, []);

  return (
    <AuthContext.Provider value={{ user, loading, loaded }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useUser = () => React.useContext(AuthContext);
export const UserProvider = AuthContext.Provider;
export const UserConsumer = AuthContext.Consumer;





export const useUserConfig = () => {
  let { user } = useUser();
  return useSWR("user-config", () => getUserConfig(user.uid), {
    revalidateOnFocus: false,
  });
};
