import React, { useEffect, useState } from "react";
import axios from "axios";
import { DatePicker, Table, Spin, message } from "antd";
import { getCitizensCountFromUsersData, getWasteEstimationDocuments } from "../API/api";
import moment from "moment";
import {
    HOST, getCurrentDateFmt
} from "../global/util";
import { UButton } from "../ui-kit";
import ExcelJS from 'exceljs';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const urlToGetHouseholdCount = HOST + "getAttendancesCounts";
const selectedMun = window.localStorage.mun;

const WasteEstimation = () => {
    const [selectedDate, setSelectedDate] = useState(moment().format("YYYY-MM-DD")); // Set default value to today's date
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sumAttendance, setSumAttendance] = useState(0)
    const [sumUserCount, setSumUserCount] = useState(0)
    const [sumTotalDryWaste, setSumTotalDryWaste] = useState(0)
    const [sumTotalWetWaste, setSumTotalWetWaste] = useState(0)
    const [sumTotalWaste, setSumTotalWaste] = useState(0)

    useEffect(() => {
        getHouseholdsData();
        getUserCount();
    }, []);

    const getUserCount = async () => {
        let userCount = await getCitizensCountFromUsersData();
        setSumUserCount(userCount)
    }

    const columns = [
        {
            title: 'Ward',
            dataIndex: 'ward',
            key: 'ward',
            width: '16.7%',
        },
        {
            title: 'Household',
            dataIndex: 'userCount',
            key: 'userCount',
            width: '16.66%',
        },
        {
            title: 'Attended Household',
            dataIndex: 'attendance',
            key: 'attendance',
            width: '16.66%',
        },
        {
            title: 'Waste (Kg)',
            dataIndex: 'totalWaste',
            key: 'totalWaste',
            width: '16.66%',
        },
        {
            title: 'Dry Waste (Kg)',
            dataIndex: 'totalDryWaste',
            key: 'totalDryWaste',
            width: '16.66%',
        },
        {
            title: 'Wet Waste (Kg)',
            dataIndex: 'totalWetWaste',
            key: 'totalWetWaste',
            width: '16.66%',
        },
        
    ];

    const naturalSort = (a, b) => {
        const splitA = a.ward.split(' ');
        const splitB = b.ward.split(' ');
        for (let i = 0; i < splitA.length; i++) {
            if (splitA[i] !== splitB[i]) {
                return splitA[i].localeCompare(splitB[i], undefined, { numeric: true });
            }
        }
        return 0;
    };

    const onChange = (date, dateString) => {
        setSelectedDate(dateString);
        getHouseholdsData(dateString);
    };

    const getHouseholdsData = async (date) => {
        setLoading(true);
        let dryWaste, wetWaste;
        const wasteEstimationData = [];
        await getWasteEstimationDocuments().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                wasteEstimationData.push({
                    id: doc.id,
                    data: doc.data()
                });
            });
            dryWaste = wasteEstimationData.find(entry => entry.id === 'dryWaste')?.data.value || 0.8;
            wetWaste = wasteEstimationData.find(entry => entry.id === 'wetWaste')?.data.value || 0.4;
        });

        let _data = {};
        date = date || (selectedDate ? selectedDate : getCurrentDateFmt());
        const url = `${urlToGetHouseholdCount}?mun=${selectedMun}&date=${date}`;

        try {
            const response = await axios.get(url);
            _data = response?.data?.attendance || {};
            const sortedData = Object.entries(_data)
                .map(([ward, values]) => {
                    const attendance = values.attendance || 0;
                    const userCount = values.userCount || 0;
                    const totalDryWaste = (attendance * dryWaste).toFixed(2);
                    const totalWetWaste = (attendance * wetWaste).toFixed(2);
                    const totalWaste = (parseFloat(totalDryWaste) + parseFloat(totalWetWaste)).toFixed(2);
                    return {
                        key: ward,
                        ward,
                        attendance,
                        userCount,
                        totalDryWaste,
                        totalWetWaste,
                        totalWaste
                    };
                })
                .sort(naturalSort);
            setData(sortedData);
            // Calculate the sum of each property
            const sumAttendanceTemp = sortedData.reduce((acc, obj) => acc + obj.attendance, 0);
            const sumTotalDryWasteTemp = sortedData.reduce((acc, obj) => acc + parseFloat(obj.totalDryWaste), 0);
            const sumTotalWetWasteTemp = sortedData.reduce((acc, obj) => acc + parseFloat(obj.totalWetWaste), 0);
            const sumTotalWasteTemp = sortedData.reduce((acc, obj) => acc + parseFloat(obj.totalWaste), 0);

            setSumAttendance(sumAttendanceTemp)
            setSumTotalWaste(sumTotalWasteTemp)
            setSumTotalDryWaste(sumTotalDryWasteTemp)
            setSumTotalWetWaste(sumTotalWetWasteTemp)
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    const downloadPDF = async () => {
        try {
            const modifiedData = data.map(item => ({
                ward: item.ward,
                userCount: item.userCount,
                attendance: item.attendance,
                totalWaste: item.totalWaste,
                totalDryWaste: item.totalDryWaste,
                totalWetWaste: item.totalWetWaste,
            }));
            let tableStartY = 15;
            const mun =
                window.localStorage.mun.charAt(0).toUpperCase() +
                window.localStorage.mun.slice(1);
            let words = "Waste Estimation Report"
            let formattedDate = selectedDate;
            const headingText = `${mun} ${words} ${formattedDate}`;
            const doc = new jsPDF("landscape");
            doc.setFontSize(18);
            doc.text(10, 10, headingText);
            tableStartY = 15;
            doc.setFontSize(12);
            const headers = Object.keys(modifiedData[0]);
            const columnLabels = {
                ward: "Ward",
                userCount: "User Count",
                attendance: "Attendance",
                totalWaste: "Total Waste",
                totalDryWaste: "Total Dry Waste",
                totalWetWaste: "Total Wet Waste",
            };
            const columnWidths = headers.map(() => "auto");
            const columnsPerPage = 17;
            const totalPages = Math.ceil(headers.length / columnsPerPage);
            const commonStyles = {
                lineWidth: 0.1,
                fontSize: 8,
                cellPadding: 2,
            };
            doc.setFontSize(8);
            doc.setTextColor(0, 0, 0);
            doc.setFont("helvetica", "bold");
            for (let page = 0; page < totalPages; page++) {
                const startIndex = page * columnsPerPage;
                const endIndex = Math.min(
                    startIndex + columnsPerPage,
                    headers.length
                );
                const pageHeaders =
                    page === 0
                        ? headers
                            .slice(startIndex, endIndex)
                            .map((key) => columnLabels[key] || key)
                        : [
                            headers[0],
                            ...headers
                                .slice(startIndex, endIndex)
                                .map((key) => columnLabels[key] || key),
                        ];
                const pageColumnKeys =
                    page === 0
                        ? headers.slice(startIndex, endIndex)
                        : [
                            headers[0],
                            ...headers.slice(startIndex, endIndex),
                        ];
                const pageTableRows = [pageHeaders];
                modifiedData.forEach((row) => {
                    const rowData = pageColumnKeys.map((header) => row[header]);
                    pageTableRows.push(rowData);
                });
                doc.autoTable({
                    startY: tableStartY,
                    head: [pageTableRows[0]],
                    body: pageTableRows.slice(1),
                    theme: "grid",
                    styles: commonStyles,
                    headStyles: {
                        fillColor: [245, 245, 245],
                        textColor: [0, 0, 0],
                        fontStyle: "bold",
                        fontSize: 10,
                    },
                });
                if (page < totalPages - 1) {
                    doc.addPage();
                }
            }
            const fileName = `${headingText}.pdf`;
            doc.save(fileName);
        } catch (error) {
            console.log("Error in downloadPDF: ", error)
            message.error({
                content:
                    "Error in downloading pdf or there is no data available at the moment to download.",
                duration: 5,
            });
        }
    }

    const downloadExcel = async () => {
        try {
            const modifiedData = data.map(item => ({
                ward: item.ward,
                userCount: item.userCount,
                attendance: item.attendance,
                totalWaste: item.totalWaste,
                totalDryWaste: item.totalDryWaste,
                totalWetWaste: item.totalWetWaste,
            }));
            const headerMapping = {
                ward: "Ward",
                userCount: "User Count",
                attendance: "Attendance",
                totalWaste: "Total Waste",
                totalDryWaste: "Total Dry Waste",
                totalWetWaste: "Total Wet Waste",
            };
            const mun =
                window.localStorage.mun.charAt(0).toUpperCase() +
                window.localStorage.mun.slice(1);
            let words = "Waste Estimation Report"
            let formattedDate = selectedDate;
            const headingText = `${mun} ${words} ${formattedDate}`;
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet(`${headingText}`);
            const headers = Object.keys(modifiedData[0]);
            const headerRow = headers.map((header) => headerMapping[header] || header);
            worksheet.addRow(headerRow).eachCell((cell) => {
                cell.font = { bold: true };
            });
            modifiedData.forEach((row) => {
                const rowData = headers.map((header) => row[header]);
                worksheet.addRow(rowData);
            });
            worksheet.columns.forEach((column) => {
                let maxLength = 0;
                column.eachCell({ includeEmpty: true }, (cell) => {
                    const length = cell.value ? cell.value.toString().length : 0;
                    if (length > maxLength) {
                        maxLength = length;
                    }
                });
                column.width = maxLength < 10 ? 10 : maxLength + 2;
            });
            const blob = await workbook.xlsx.writeBuffer();
            const blobObject = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const fileName = `${headingText}.xlsx`;
            const url = window.URL.createObjectURL(blobObject);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.log("Error in downloadExcel: ", error)
            message.error({ content: "Error in downloading excel or there is no data available at the moment to download.", duration: 5 })
        }
    }

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "flex-end", position: "absolute", top: "63px", right: "10px" }}>
                <div style={{ width: "152px", backgroundColor: "white", height: "42px", marginRight: "7px", marginTop: "-1px" }}></div>
                <UButton w={150} mr={8} onClick={() => downloadExcel()}>Download Excel</UButton>
                <UButton w={150} onClick={() => downloadPDF()}>Download PDF </UButton>
            </div>
            <div style={{ display: "flex", justifyContent:"flex-end", position:"absolute", top:"115px", right:"5px" }}>
                {/* <Header t={"Estimated Waste Collected"} /> */}
                
                <DatePicker
                    style={{ margin: "8px 20px" }}
                    onChange={onChange}
                    defaultValue={moment(selectedDate)}
                />
            </div>

            <Spin spinning={loading}>
                {/* <div style={{ maxHeight: "calc(100vh - 95px)", overflowY: "auto" , marginTop: "55px"}}> */}
                <p><strong>Total Household:</strong> <em>{sumUserCount}</em> <strong>Total Attended:</strong> <em>{sumAttendance}</em> <strong>Total Waste:</strong> <em>{sumTotalWaste.toFixed(2)}</em> <strong>Total Dry Waste:</strong> <em>{sumTotalDryWaste.toFixed(2)}</em> <strong>Total Wet Waste:</strong> <em>{sumTotalWetWaste.toFixed(2)}</em></p>

                    <Table columns={columns} dataSource={data} pagination={false} />
                {/* </div> */}
            </Spin>
        </div>
    );
};

export default WasteEstimation;