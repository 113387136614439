import React, { useState, useEffect } from 'react';
import { DatePicker, Spin, message, Table, Button, Menu, Dropdown } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { createVehicleSummaryReportsRef, getReportsOfVehicles, getVehicleSummaryReports } from '../../API/api';
import ExcelJS from 'exceljs';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { DownloadOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

const CustomDateRangeFuel = ({ vehicles }) => {

    const [selectedRange, setSelectedRange] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);

    const isRangeSelected = selectedRange.length !== 0;

    const handleDateChange = (dates) => {
        const formattedDates = [];
        const startDate = new Date(dates[0]);
        const endDate = new Date(dates[1]);
        const currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            const year = currentDate.getFullYear();
            const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
            const day = currentDate.getDate().toString().padStart(2, '0');
            const formattedDate = `${day}-${month}-${year}`;
            formattedDates.push(formattedDate);
            currentDate.setDate(currentDate.getDate() + 1);
        }
        setSelectedRange(formattedDates);
    };

    const disabledDate = current => {
        return current && current > moment().endOf('day');
    };

    useEffect(() => {
        getVehicleSummaryReport();
    }, [selectedRange, vehicles]);

    const getVehicleSummaryReport = async () => {
        try {
            setLoading(true)
            if (selectedRange.length === 0) {
                setDataSource([]);
                setLoading(false);
                return;
            }
            const docRefs = await getVehicleSummaryReports(selectedRange);
            const currentDateSnapshot = [];
            const missingSnapshots = [];
            let currentData = [];
            let missingData = [];
            const getCurrentDate = () => {
                const currentDate = new Date();
                const day = currentDate.getDate().toString().padStart(2, '0');
                const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
                const year = currentDate.getFullYear().toString();
                return `${year}-${month}-${day}`;
            }
            let currentDate = getCurrentDate();
            const convertToHoursAndMinutes = (totalHours) => {
                if (window.localStorage.mun == "dehri") {
                    totalHours = totalHours * 1000
                }
                let t_en_minutes = Math.floor((totalHours / (1000 * 60)) % 60);
                let t_en_hours = Math.floor((totalHours / (1000 * 60 * 60)) % 24);
                const formattedHours = String(t_en_hours).padStart(2, '0');
                const formattedMinutes = String(t_en_minutes).padStart(2, '0');
                return `${formattedHours}:${formattedMinutes}`;
            }
            const formatDate = (inputDate) => {
                const date = new Date(inputDate);
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const year = date.getFullYear();
                const formattedDay = String(day).padStart(2, '0');
                const formattedMonth = String(month).padStart(2, '0');
                return `${formattedDay}-${formattedMonth}-${year}`;
            }
            const formatDateReverse = (inputDate) => {
                const [year, month, day] = inputDate.split('-');
                return `${day}-${month}-${year}`;
            }
            const results = await Promise.all(docRefs.map(async (docRef) => {
                try {
                    const snapshot = await docRef.get();
                    if (snapshot.exists) {
                        const date = docRef.id;
                        if (snapshot.data().rows) {
                            const rowsData = snapshot.data().rows;
                            const rowsWithDate = rowsData.map(row => ({ date, ...row }));
                            return { date, rows: rowsWithDate };
                        } else {
                            console.log(`No data found for date: ${docRef.id}`);
                            return null;
                        }
                    } else if (!snapshot.exists) {
                        console.log(`No date ${docRef.id} exist in summary`);
                        if (docRef.id === currentDate) {
                            currentDateSnapshot.push(formatDateReverse(docRef.id));
                            const vehicles_reports = await getReportsOfVehicles(currentDateSnapshot);
                            vehicles_reports?.docs?.map((eachDoc) => {
                                let docData = eachDoc.data();
                                if (docData.vehicle_name && docData.vehicle_num) {
                                    let data_info = {
                                        date: docData.date,
                                        device_id_now: docData.device_id || "",
                                        distance: docData.distance ? docData.distance.toFixed(2) : 0,
                                        engine_hours: convertToHoursAndMinutes(docData.engine_hours) || "00.00",
                                        quantity: docData.quantity || "0",
                                        vehicle_num: docData.vehicle_num || "",
                                    };
                                    currentData.push(data_info);
                                }
                            });
                            return null;
                        } else {
                            missingSnapshots.push(formatDateReverse(docRef.id));
                            const vehicles_reports = await getReportsOfVehicles(missingSnapshots);
                            vehicles_reports?.docs?.map((eachDoc) => {
                                let docData = eachDoc.data();
                                if (docData.vehicle_name && docData.vehicle_num) {
                                    let data_info = {
                                        average_speed: docData.average_speed || 0,
                                        device_id: docData.device_id || "",
                                        distance: docData.distance ? docData.distance.toFixed(2) : 0,
                                        duration: docData.duration || "00:00",
                                        engine_hours: convertToHoursAndMinutes(docData.engine_hours) || "00.00",
                                        quantity: docData.quantity || "0",
                                        ignition_time: docData.ignition_time || "00.00",
                                        max_speed: docData.max_speed || 0,
                                        phone_num: docData.phone_num || "",
                                        vehicle_name: docData.vehicle_name || "",
                                        vehicle_num: docData.vehicle_num || "",
                                        date: docData.date,
                                    };
                                    missingData.push(data_info);
                                }
                            });
                            const organizedData = missingData.reduce((acc, item) => {
                                const date = item.date;
                                if (!acc[date]) {
                                    acc[date] = [];
                                }
                                acc[date].push(item);
                                return acc;
                            }, {});

                            const organizedArray = Object.keys(organizedData).map(date => ({
                                date,
                                rows: organizedData[date],
                            }));
                            createVehicleSummaryReportsRef(missingSnapshots, organizedArray);
                            return null;
                        }
                    }
                } catch (error) {
                    console.error(`Error fetching data for reference: ${docRef.path}`, error);
                    return null;
                }
            }));
            const processedData = results.flatMap(result => {
                if (result) {
                    const rows = result.rows;
                    return rows.map(row => ({
                        date: formatDate(result.date),
                        vehicle_num: row.vehicle_num || "",
                        distance: row.distance || "0",
                        engine_hours: row.engine_hours || "00:00",
                        quantity: row.quantity || "0",
                        device_id_now: row.device_id || "",
                    }));
                }
                return [];
            });
            if (missingData.length > 0) {
                processedData.push(...missingData);
            }
            if (currentData.length > 0) {
                processedData.push(...currentData);
            }
            if (processedData.length > 0) {
                calculateVehicleSummaryData(processedData);
            } else {
                setLoading(false)
            }
        } catch (e) {
            console.log("Error in getVehicleSummaryReport: ", e);
        }
    }

    const calculateVehicleSummaryData = (reports_data) => {
        const allSummary = [];
        const newSummary = [];
        for (let i = 0; i < vehicles.length; i++) {
            let dis = 0;
            let quantity = 0;
            let total_min = 0;
            let total_hours = 0;
            const map1 = {
                vehicle_num: vehicles[i].vehicle_id,
            };
            reports_data.forEach((val) => {
                if (val.device_id_now === vehicles[i].device_id) {
                    dis += parseFloat(val.distance);
                    quantity += parseFloat(val.quantity)
                    const engineHours = val.engine_hours.split(':');
                    const engine_hrs_v = parseInt(engineHours[0]) * 60 + parseInt(engineHours[1]);
                    const en_min = engine_hrs_v % 60;
                    total_min += en_min;
                    const en_hrs = Math.floor(engine_hrs_v / 60);
                    total_hours += en_hrs;
                    const t_min = en_min < 10 ? "0" + en_min : en_min;
                    const t_hrs = en_hrs < 10 ? "0" + en_hrs : en_hrs;
                    const en_tm = isNaN(engine_hrs_v) ? "00:00" : t_hrs + ":" + t_min;
                    map1[`${val.date.substring(0, 2)}eng`] = en_tm || "00:00";
                    map1[`${val.date.substring(0, 2)}dis`] = val.distance || "0";
                    map1[`${val.date.substring(0, 2)}qua`] = val.quantity || "0";
                    const smry = {
                        vehicle_num: vehicles[i].vehicle_id || "",
                        distance: parseFloat(val.distance) || 0,
                        quantity: parseFloat(val.quantity) || 0,
                        tot_dis: dis.toFixed(2),
                        engine_hours: en_tm || "00:00",
                        date: val.date || '',
                    };
                    newSummary.push(smry);
                }
            });
            const tot_min_hr = Math.floor(total_min / 60);
            const tot_min_min = total_min % 60;
            const total_hr_hours = total_hours + tot_min_hr;
            const tot_hr_min = `${total_hr_hours < 10 ? "0" + total_hr_hours : total_hr_hours}:${tot_min_min < 10 ? "0" + tot_min_min : tot_min_min}`;
            map1['total_distance'] = dis.toFixed(2);
            map1['total_engine'] = tot_hr_min;
            map1['total_quantity'] = quantity.toFixed(2);
            allSummary.push(map1);
        }
        formatedCSV(allSummary)
    }

    const formatedCSV = (data) => {
        let csvData = [];
        data.forEach((csv) => {
            let formattedData = {};
            formattedData["Vehicle_Number"] = csv.vehicle_num;
            formattedData["Total_Distance"] = csv.total_distance;
            formattedData["Total_EngineHours"] = csv.total_engine;
            formattedData["Total_FuelQuantity"] = csv.total_quantity;
            selectedRange.forEach((date) => {
                const [day, month, year] = date.split("-");
                const key_dis = `${day}-${month}_Distance`;
                const key_eng = `${day}-${month}_EngineHours`;
                const key_qua = `${day}-${month}_FuelQuantity`;
                formattedData[key_dis] = csv[`${day}dis`] || "0";
                formattedData[key_eng] = csv[`${day}eng`] || "0";
                formattedData[key_qua] = csv[`${day}qua`] || "0";
            });
            csvData.push(formattedData);
        });
        setDataSource(csvData)
        setLoading(false)
    }

    const downloadPDF = async (data) => {
        try {
            let tableStartY = 15;
            const mun =
                window.localStorage.mun.charAt(0).toUpperCase() +
                window.localStorage.mun.slice(1);
            let words = "Fuel Summary Report"
            const headingText = `${mun} ${words} ${selectedRange[0]} - ${selectedRange[selectedRange.length - 1]}`;
            const doc = new jsPDF("landscape");
            doc.setFontSize(18);
            doc.text(10, 10, headingText);
            tableStartY = 15;
            doc.setFontSize(12);
            const headers = Object.keys(data[0]);
            const columnLabels = {
                Vehicle_Number: "Vehicle Number",
                Total_Distance: "Total Distance",
                Total_EngineHours: "Total Engine Hours",
                Total_FuelQuantity: "Total Fuel Quantity",
            };
            const columnWidths = headers.map(() => "auto");
            const columnsPerPage = 17;
            const totalPages = Math.ceil(headers.length / columnsPerPage);
            const commonStyles = {
                lineWidth: 0.1,
                fontSize: 8,
                cellPadding: 2,
            };
            doc.setFontSize(8);
            doc.setTextColor(0, 0, 0);
            doc.setFont("helvetica", "bold");
            for (let page = 0; page < totalPages; page++) {
                const startIndex = page * columnsPerPage;
                const endIndex = Math.min(
                    startIndex + columnsPerPage,
                    headers.length
                );
                const pageHeaders =
                    page === 0
                        ? headers
                            .slice(startIndex, endIndex)
                            .map((key) => columnLabels[key] || key)
                        : [
                            headers[0],
                            ...headers
                                .slice(startIndex, endIndex)
                                .map((key) => columnLabels[key] || key),
                        ];
                const pageColumnKeys =
                    page === 0
                        ? headers.slice(startIndex, endIndex)
                        : [
                            headers[0],
                            ...headers.slice(startIndex, endIndex),
                        ];
                const pageTableRows = [pageHeaders];
                data.forEach((row) => {
                    const rowData = pageColumnKeys.map((header) => row[header]);
                    pageTableRows.push(rowData);
                });
                doc.autoTable({
                    startY: tableStartY,
                    head: [pageTableRows[0]],
                    body: pageTableRows.slice(1),
                    theme: "grid",
                    styles: commonStyles,
                    headStyles: {
                        fillColor: [245, 245, 245],
                        textColor: [0, 0, 0],
                        fontStyle: "bold",
                        fontSize: 10,
                    },
                });
                if (page < totalPages - 1) {
                    doc.addPage();
                }
            }
            const fileName = `${headingText}.pdf`;
            doc.save(fileName);
        } catch (error) {
            console.log("Error in downloadPDF: ", error)
            message.error({
                content:
                    "Error in downloading pdf or there is no data available at the moment to download.",
                duration: 5,
            });
        }
    }

    const downloadExcel = async (data) => {
        try {
            const headerMapping = {
                Vehicle_Number: "Vehicle Number",
                Total_Distance: "Total Distance",
                Total_EngineHours: "Total Engine Hours",
                Total_FuelQuantity: "Total Fuel Quantity",
            };
            const mun =
                window.localStorage.mun.charAt(0).toUpperCase() +
                window.localStorage.mun.slice(1);
            let words = "Fuel Summary Report"
            const headingText = `${mun} ${words} ${selectedRange[0]} - ${selectedRange[selectedRange.length - 1]}`;
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet(`${headingText}`);
            const headers = Object.keys(data[0]);
            const headerRow = headers.map((header) => headerMapping[header] || header);
            worksheet.addRow(headerRow).eachCell((cell) => {
                cell.font = { bold: true };
            });
            data.forEach((row) => {
                const rowData = headers.map((header) => row[header]);
                worksheet.addRow(rowData);
            });
            worksheet.columns.forEach((column) => {
                let maxLength = 0;
                column.eachCell({ includeEmpty: true }, (cell) => {
                    const length = cell.value ? cell.value.toString().length : 0;
                    if (length > maxLength) {
                        maxLength = length;
                    }
                });
                column.width = maxLength < 10 ? 10 : maxLength + 2;
            });
            const blob = await workbook.xlsx.writeBuffer();
            const blobObject = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const fileName = `${headingText}.xlsx`;
            const url = window.URL.createObjectURL(blobObject);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.log("Error in downloadExcel: ", error)
            message.error({ content: "Error in downloading excel or there is no data available at the moment to download.", duration: 5 })
        }
    }

    const handleDownload = (type) => {
        if (dataSource.length == 0) {
            message.error('No Data Available To Download');
            return;
        }
        if (type === 'pdf') {
            message.info('PDF download initiated');
            downloadPDF(dataSource)
        } else if (type === 'excel') {
            message.info('Excel download initiated');
            downloadExcel(dataSource)
        }
    };

    const menu = () => {
        return (
            <Menu onClick={e => handleDownload(e.key)}>
                <Menu.Item key="pdf" style={{ display: 'flex', alignItems: 'center' }}>
                    <DownloadOutlined style={{ verticalAlign: 'middle', marginRight: '8px' }} />
                    PDF
                </Menu.Item>
                <Menu.Item key="excel" style={{ display: 'flex', alignItems: 'center' }}>
                    <DownloadOutlined style={{ verticalAlign: 'middle', marginRight: '8px' }} />
                    Excel
                </Menu.Item>
            </Menu>
        );
    };

    const columns = [
        {
            title: 'Vehicle Number',
            dataIndex: 'Vehicle_Number',
            key: 'Vehicle_Number',
        },
        {
            title: 'Total',
            children: [
                {
                    title: 'Distance',
                    dataIndex: 'Total_Distance',
                    key: 'Total_Distance',
                },
                {
                    title: 'Engine Hours',
                    dataIndex: 'Total_EngineHours',
                    key: 'Total_EngineHours',
                },
                {
                    title: 'Fuel Quantity',
                    dataIndex: 'Total_FuelQuantity',
                    key: 'Total_FuelQuantity',
                },
            ],
        },
    ];

    // Dynamically generate columns based on selectedRange
    selectedRange.forEach(date => {
        const shortDate = date.substring(0, 5);
        columns.push({
            title: date,
            children: [
                {
                    title: 'Distance',
                    dataIndex: `${shortDate}_Distance`,
                    key: `${shortDate}_Distance`,
                },
                {
                    title: 'Engine Hours',
                    dataIndex: `${shortDate}_EngineHours`,
                    key: `${shortDate}_EngineHours`,
                },
                {
                    title: 'Fuel Quantity',
                    dataIndex: `${shortDate}_FuelQuantity`,
                    key: `${shortDate}_FuelQuantity`,
                },
            ]
        });
    });

    return (
        <div style={{ position: 'relative', marginRight: "10px" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
                {isRangeSelected ? (
                    <p style={{ marginBottom: "0" }}>Selected Date Range: {selectedRange[0]} - {selectedRange[selectedRange.length - 1]}</p>
                ) : (
                    <p style={{ marginBottom: "0" }}>Selected Date Range: N/A</p>
                )}
                <RangePicker
                    defaultValue={selectedRange}
                    onChange={handleDateChange}
                    disabledDate={disabledDate}
                />
                <Dropdown overlay={menu}>
                    <Button>Download</Button>
                </Dropdown>
            </div>
            <div style={{ maxHeight: "calc(100vh - 200px)", overflow: "auto" }}>
                <Spin spinning={loading} size="large">
                    <Table dataSource={dataSource} columns={columns} pagination={false} />
                </Spin>
            </div>
        </div>
    );
};

CustomDateRangeFuel.propTypes = {
    vehicles: PropTypes.array.isRequired,
};

export default CustomDateRangeFuel;
