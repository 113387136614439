
import React, { useState } from 'react';
import { Form, Input, Button, message, Modal } from 'antd';

import LogoImg from '../Utils/images/binimise_logo.png';

import { Color } from '../util';
import 'antd/dist/antd.css';

const DeleteUser = () => {
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleSubmit = () => {
        form
            .validateFields()
            .then((values) => {
                showModal();
                // You can also send the form data to a backend server here if needed

                form.resetFields();
            })
            .catch((errorInfo) => {
                console.log('Validation failed:', errorInfo);
            });
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div style={{ minHeight: '95vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ maxWidth: '400px' }}>
                <div className="flex justify-center align-center">
                    <img src={LogoImg} className="" style={{ width: 200 }} alt="Logo" />
                </div>
                <h1 className="login-text" style={{ color: Color.DeleteUser }}>Delete User</h1>
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <Form.Item
                        label="Municipality Name"
                        name="municipalityName"
                        rules={[
                            { required: true, message: 'Please enter municipality name' },
                        ]}
                    >
                        <Input placeholder="Enter municipality name" />
                    </Form.Item>
                    <Form.Item
                        label="Phone Number"
                        name="phoneNumber"
                        rules={[
                            { required: true, message: 'Please enter phone number' },
                            {
                                pattern: /^\d{10}$/,
                                message: 'Please enter a valid 10-digit phone number',
                            },
                        ]}
                    >
                        <Input type="number" placeholder="Enter phone number" />
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 24 }}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div>

            <Modal
                title="Success"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="submit" type="primary" onClick={handleOk}>
                        Close
                    </Button>,
                ]}
            >
                <p>Our support team will contact you within 2-3 business days!</p>
            </Modal>
        </div>
    );
};

export default DeleteUser;

