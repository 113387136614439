import React from "react";

function NotFound() {
    return (
      <div>
        <div className="container">
          <div className="row justify-content-center">
              <h1>404</h1>
              <h1>{'Not Found'}</h1>
          </div>
        </div>
      </div>
    );
}

export default NotFound;
