/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from 'react';
import { Color } from "./../../src/global/util";

export default (props) => {
  let { s, a, c, b, t, u, bc, lh, to, ri, mt, mr, ml, mb, w, h, boc, center, style, ...rest } = props

  let st = {
    ...{
      color: c ? c : Color.themeFontColor,
      fontSize: s,
      lineHeight: lh,
      fontWeight: b ? 'bold' : undefined,
      textAlign: center ? 'center' : undefined,
      marginTop: mt ? mt : undefined,
      marginRight: mr ? mr : undefined,
      marginBottom: mb ? mb : undefined,
      marginLeft: ml ? ml : undefined,
      width: 'fit-content',
      height: h ? h : 40,
      top: to ? to : undefined,
      right: ri ? ri : undefined,
      borderColor: boc ? boc : Color.themeFontColor,
      backgroundColor: bc ? bc : undefined,
      position: a ? "absolute" : undefined,
      textDecorationLine: u ? 'underline' : undefined,
    }, ...style
  };

  return (
    <button type="button" className="ant-btn flex justify-center" onClick={props.onClick}
      {...rest} style={st}>
      {props.children}
    </button>
  );
}